import React from "react";
import Footer from "../components/Footer";
function OurCommitment() {
  return (
    <section data-aos="fade-up">
      <div>
        <h1 className="global-office pt-5">Our Commitment</h1>
        <p className="global-office testimonial-text office-p">
          As retained executive search professionals, the insights we bring and
          the advice we impart
          <br /> can have a significant impact on the businesses, careers and
          lives of others.
        </p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="pt-5">
              <h3 className="py-3">HONESTY & INTEGRITY</h3>
              <p>
                We adhere to a code of professional ethics with an emphasis on
                honesty and integrity — handling our relationships with clients,
                candidates and colleagues with great care.
              </p>
              <h3 className="py-3">QUALITY & PROFESSIONALISM</h3>
              <p>
                We value quality and professionalism in everything we do. With a
                relentless dedication to quality work, the service we provide is
                thorough, efficient, and tailored to meet the unique needs of
                each client.
              </p>
              <h3 className="py-3">COMMITMENT TO DELIVER RESULTS</h3>
              <p>
                As our clients' trusted advisor, we have a commitment to deliver
                results, without exception. We strive to create value for our
                clients enabling them to build sustainability and long-term
                success through outstanding leadership teams.
              </p>
              <h3 className="py-3">TEAMWORK & KNOWLEDGE</h3>
              <p>
                We draw our energy and creativity from working together. As a
                global organization, we foster teamwork and knowledge sharing
                through our global practice structure, while retaining strong
                local capabilities in each of our offices.
              </p>
              <h3 className="py-3">ENTREPRENEURIAL SPIRIT</h3>
              <p>
                At the same time, we operate in an entrepreneurial framework,
                allowing for flexibility, inventiveness and resourcefulness in
                fulfilling our clients' most demanding requirements.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default OurCommitment;
